<template>
  <div class="kline-page">
    <div :class="{'full-page': enlargeShow}">
      <div class="clearfix header">
        <span>K线复盘</span>
        <div class="header-absolute">
          <span class="my-dropdown-link" @click="changeHyText">
            {{ hyText }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown trigger="click" placement="bottom-start" @command="changeFirstText">
            <span class="el-dropdown-link">
              {{ firstText }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="daily">日线</el-dropdown-item>
              <el-dropdown-item command="minute">1分钟</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          
          <span class="enlarge-btn" @click="fullPage">【{{ enlargeShow ? '缩小':'放大'}}】</span>
        </div>
      </div>
      <!-- <el-header>
        <el-col :span="8">
          <el-dropdown trigger="click" placement="bottom-start" @command="changeAccount">
            <span class="el-dropdown-link">
              {{curAccountName}}{{curAccount}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in accounts" :key="item.id" :command="item.id">
                <p>{{item.nickname}} ({{item.account}})</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-header> -->
      <div class="chart-box">
        <div ref="chart" class="chart"></div>
        <div class="k-tooltip" v-show="(hyText!==''&& hyText!=='请选择合约')">
          <div>
            <p v-for="(item, index) in kTooltips.renderData" :key="index">
              <span>{{ item.name }}{{kTooltips.renderData?': ':''}}</span>
              <span :style="index ===0 ? '' : { color: kTooltips.color}">{{ item.value }}</span>
              <span v-show="item.upOrDown"
                    :style="index ===0 ? '' : { color: kTooltips.color}">{{ item.upOrDown }}</span>
            </p>
          </div>
          <div v-show="kTooltips.color">
            <!-- <el-dropdown trigger="click" placement="bottom-start" @command="changeFirstText">
            <span class="el-dropdown-link">
              {{ firstText }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="5分钟">5分钟</el-dropdown-item>
                <el-dropdown-item command="15分钟">15分钟</el-dropdown-item>
                <el-dropdown-item command="30分钟">30分钟</el-dropdown-item>
                <el-dropdown-item command="60分钟">60分钟</el-dropdown-item>
                <el-dropdown-item command="周线">周线</el-dropdown-item>
                <el-dropdown-item command="日线">日线</el-dropdown-item>
                <el-dropdown-item command="月线">月线</el-dropdown-item>
                <el-dropdown-item command="年线">年线</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <p :style="{ color: kMA.ma5&&kMA.ma5.color}">
              <span>{{ kMA.ma5&&kMA.ma5.name }}{{kMA.ma5&&kMA.ma5.color?': ':''}}</span>
              <span>{{ kMA.ma5&&kMA.ma5.value }}</span>
              <span v-show="kMA.ma5&&kMA.ma5.upOrDown">{{ kMA.ma5&&kMA.ma5.upOrDown }}</span>
            </p>
            <p :style="{ color: kMA.ma10&&kMA.ma10.color}">
              <span>{{ kMA.ma10&&kMA.ma10.name }}{{kMA.ma10&&kMA.ma10.color?': ':''}}</span>
              <span>{{ kMA.ma10&&kMA.ma10.value }}</span>
              <span v-show="kMA.ma10&&kMA.ma10.upOrDown">{{ kMA.ma10&&kMA.ma10.upOrDown }}</span>
            </p>
            <p :style="{ color: kMA.ma20&&kMA.ma20.color}">
              <span>{{ kMA.ma20&&kMA.ma20.name }}{{kMA.ma20&&kMA.ma20.color?': ':''}}</span>
              <span>{{ kMA.ma20&&kMA.ma20.value }}</span>
              <span v-show="kMA.ma20&&kMA.ma20.upOrDown">{{ kMA.ma20&&kMA.ma20.upOrDown }}</span>
            </p>
            <p :style="{ color: kMA.ma30&&kMA.ma30.color}">
              <span>{{ kMA.ma30&&kMA.ma30.name }}{{kMA.ma30&&kMA.ma30.color?': ':''}}</span>
              <span>{{ kMA.ma30&&kMA.ma30.value }}</span>
              <span v-show="kMA.ma30&&kMA.ma30.upOrDown">{{ kMA.ma30&&kMA.ma30.upOrDown }}</span>
            </p>
          </div>
        </div>
        <div class="vol-tooltip" v-show="(hyText!==''&& hyText!=='请选择合约')">
          <el-dropdown trigger="click" placement="bottom-start" @command="changeSecondText">
            <span class="el-dropdown-link">
              {{ secondText }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="VOL(5,10)">VOL(5,10)</el-dropdown-item>
              <el-dropdown-item command="RSI(6,12,24)">RSI(6,12,24)</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <p :style="{ color: volTooltips&&volTooltips.color}">
            <span>{{ volTooltips&&volTooltips.name }}{{volTooltips.color?': ':''}}</span>
            <span>{{ volTooltips&&volTooltips.value }}</span>
            <span v-show="volTooltips&&volTooltips.upOrDown">{{ volTooltips.upOrDown }}</span>
          </p>
          <p :style="{ color: volMA.ma5&&volMA.ma5.color}">
            <span>{{ volMA.ma5&&volMA.ma5.name }}{{volMA.ma5&&volMA.ma5.color?': ':''}}</span>
            <span>{{ volMA.ma5&&volMA.ma5.value }}</span>
            <span v-show="volMA.ma5&&volMA.ma5.upOrDown">{{ volMA.ma5&&volMA.ma5.upOrDown }}</span>
          </p>
          <p :style="{ color: volMA.ma10&&volMA.ma10.color}">
            <span>{{ volMA.ma10&&volMA.ma10.name }}{{volMA.ma10&&volMA.ma10.color?': ':''}}</span>
            <span>{{ volMA.ma10&&volMA.ma10.value }}</span>
            <span v-show="volMA.ma10&&volMA.ma10.upOrDown">{{ volMA.ma10&&volMA.ma10.upOrDown }}</span>
          </p>
        </div>
        <div class="macd-tooltip" v-show="(hyText!==''&& hyText!=='请选择合约')">
          <!-- <el-dropdown trigger="click" placement="bottom-start" @command="changeThirdText">
            <span class="el-dropdown-link">
              {{ thirdText }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="MACD(12,26,9)">MACD(12,26,9)</el-dropdown-item>
              <el-dropdown-item command="KDJ(9,3,3)">KDJ(9,3,3)</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <span class="tooltip-title">
            {{ thirdText }}
          </span>
          <p :style="{ color: macdTooltips&&macdTooltips.color}">
            <span>{{ macdTooltips&&macdTooltips.name }}{{macdTooltips.color?': ':''}}</span>
            <span>{{ macdTooltips&&macdTooltips.value }}</span>
            <span v-show="macdTooltips&&macdTooltips.upOrDown">{{ macdTooltips.upOrDown }}</span>
          </p>
          <p :style="{ color: difTooltips&&difTooltips.color}">
            <span>{{ difTooltips&&difTooltips.name }}{{difTooltips.color?': ':''}}</span>
            <span>{{ difTooltips&&difTooltips.value }}</span>
            <span v-show="difTooltips&&difTooltips.upOrDown">{{ difTooltips.upOrDown }}</span>
          </p>
          <p :style="{ color: deaTooltips&&deaTooltips.color}">
            <span>{{ deaTooltips&&deaTooltips.name }}{{deaTooltips.color?': ':''}}</span>
            <span>{{ deaTooltips&&deaTooltips.value }}</span>
            <span v-show="deaTooltips&&deaTooltips.upOrDown">{{ deaTooltips.upOrDown }}</span>
          </p>
        </div>
      </div>
    </div>
    <el-dialog
      title="请选择合约"
      :visible.sync="dialogVisible"
      width="20%"
      >
      <el-select v-model="typeValue" filterable remote placeholder="请输入关键字" :loading="selectLoading" :remote-method="remoteMethod">
        <el-option
          v-for="item in options"
          :key="item.c"
          :label="`${item.n} (${item.c})`"
          :value="item.c"
          :value-key="item">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hyConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
// import {Loading} from "element-ui";
import { apiKline, apiContract, apiKlinePoint } from "../../utils/api";
//apiKline

let chart = null
let data = {
  datas: []
}
let totalData = []
export default {
  name: "Kline",
  data () {
    return {
      page: 1,
      selectedAccountId: this.$store.state.curAccountId,
      enlargeShow: false,
      typeValue: '',
      options: [],
      selectLoading:  false,
      hyText:'请选择合约',
      dialogVisible: true,
      firstText: '日线',
      secondText: 'VOL(5,10)',
      thirdText: 'KDJ(9,3,3)',
      kTooltips: {},
      volTooltips: {},
      kMA: {
        MAData5: [],
        MAData10: [],
        MAData20: [],
        MAData30: []
      },
      volMA: {
        MAData5: [],
        MAData10: []
      },
      macdTooltips: {},
      difTooltips: {},
      deaTooltips: {},
      data: null,
      option: {
        backgroundColor: '#fff',
        tooltip: {
          show: true,
          position: [10, 10],
          triggerOn: 'mousemove|click',
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true,
            crossStyle: {
              type: 'solid',
              color: '#666'
            },
            lineStyle: {
              type: 'solid',
              color: '#666'
            }
          }
        },
        grid: [
          {
            left: '1%',
            right: '1%',
            height: '60%'
          }, {
            left: '1%',
            right: '1%',
            top: '70%',
            height: '10%'
          }, {
            left: '1%',
            right: '1%',
            top: '84%',
            height: '12%'
          }],
        xAxis: [
          {
            show: true,
            scale: true,
            nameGap: 15,
            splitNumber: 5,
            gridIndex: 0,
            axisLine: {show: true},
            splitLine: {show: false},
            axisLabel: {show: false},
            axisTick: {
              show: false
            },
            axisPointer: {
              label: {
                show: false
              }
            },
            min: 'dataMin',
            max: 'dataMax'
          },
          {
            show: true,
            scale: true,
            gridIndex: 1,
            nameGap: 15,
            splitNumber: 5,
            axisLine: {show: true},
            splitLine: {show: false},
            axisLabel: {show: false},
            axisTick: {
              show: false
            },
            axisPointer: {
              label: {
                show: false
              }
            },
          },
          {
            show: true,
            scale: true,
            gridIndex: 2,
            nameGap: 15,
            splitNumber: 5,
            axisLine: {show: true},
            axisTick: {
              show: false
            },
          }
        ],
        yAxis: [
          {
            scale: true,
            splitNumber: 4,
            axisLine: {
              lineStyle: {
                color: '#4a657a'
              }
            },
            axisLabel: {
              show: false,
              color: '#4a657a'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#4a657a',
                type: 'dashed'
              }
            },
            splitArea: {
              show: false
            }
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 1,
            axisLine: {
              lineStyle: {
                color: '#4a657a'
              }
            },
            axisTick: {show: false},
            splitLine: {
              show: true,
              lineStyle: {
                color: '#4a657a',
                type: 'dashed'
              }
            },
            axisLabel: {show: false}
          },
          {
            scale: true,
            gridIndex: 2,
            splitNumber: 1,
            axisLine: {
              lineStyle: {
                color: '#4a657a'
              }
            },
            axisTick: {show: false},
            splitLine: {
              show: true,
              lineStyle: {
                color: '#4a657a',
                type: 'dashed'
              }
            },
            axisLabel: {show: false}
          }],
        axisPointer: {
          show: true,
          type: 'line',
          link: [{
            xAxisIndex: 'all'
          }]
        },
        dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0, 0],
          filterMode: 'empty',
          startValue: 5,
          endValue: 49
        },
        {
          xAxisIndex: [0, 1],
          type: 'inside',
          filterMode: 'empty',
          top: '97%',
          startValue: 5,
          endValue: 49
        },
        {
          type: 'inside',
          xAxisIndex: [0, 2],
          filterMode: 'empty',
          startValue: 5,
          endValue: 49
        }],
        series: [
          {
            name: 'KLine',
            type: 'candlestick',
            data: [],
            itemStyle: {
              color: '#ef232a',
              color0: '#14b143',
              borderColor: '#ef232a',
              borderColor0: '#14b143'
            },
            markPoint: {
              symbolSize: 0,
              label: {
                show: true,
                fontSize: 16,
                color: '#333',
                formatter: (param) => {
                  let val = ''
                  if (param.name === '最低价') {
                    val = '← ' + param.value.toFixed(2)
                  } else if (param.name === '最高价') {
                    val = param.value.toFixed(2) + ' →'
                  } else if (param.name === '开仓') {
                    val = '▲\n' + param.value
                  } else if (param.name === '平仓') {
                    val = param.value + '\n▼'
                  }
                  return val
                }
              },
              data: []
            },
            markLine: {
              symbol: ['circle', 'arrow'],
              symbolSize: 8,
              lineStyle: {
                width: 2,
                color: '#1777FF'
              },
              data: []
            }
          },
          {
            name: 'VOL',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: [],
            itemStyle: {
              color: function (params) {
                let colorList;
                if (data.datas[params.dataIndex][1] > data.datas[params.dataIndex][0]) {
                  colorList = '#ef232a';
                } else {
                  colorList = '#14b143';
                }
                return colorList;
              },
            }
          },
          {
            name: 'K',
            type: 'line',
            showSymbol: false,
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: [],
            smooth: true,
            color: '#e6a23c',
            lineStyle: {
              width: 1
            }            
          },
          {
            name: 'D',
            type: 'line',
            showSymbol: false,
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: [],
            color: '#CC00FF',
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'J',
            type: 'line',
            showSymbol: false,
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: [],
            color: '#DD0',
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'MA5',
            type: 'line',
            showSymbol: false,
            data: [],
            smooth: true,
            color: '#e6a23c',
            lineStyle: {
              width: 1,
              opacity: 0.5
            }
          },
          {
            name: 'MA10',
            type: 'line',
            showSymbol: false,
            data: [],
            smooth: true,
            color: '#CC00FF',
            lineStyle: {
              width: 1,
              opacity: 0.5
            }
          },
          {
            name: 'MA20',
            type: 'line',
            showSymbol: false,
            data: [],
            smooth: true,
            color: '#DD0',
            lineStyle: {
              width: 1,
              opacity: 0.5
            }
          },
          {
            name: 'MA30',
            type: 'line',
            showSymbol: false,
            data: [],
            smooth: true,
            color: '#0099FF',
            lineStyle: {
              width: 1,
              opacity: 0.5
            }
          },
          {
            name: 'MA5',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            showSymbol: false,
            data: [],
            smooth: true,
            color: '#e6a23c',
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'MA10',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            showSymbol: false,
            data: [],
            smooth: true,
            color: '#CC00FF',
            lineStyle: {
              width: 1
            }
          },
        ]
      }
    }
  },
  computed: {
    accounts: function () {
      return this.$store.state.accounts;
    },
    curAccount: function () {
      if (this.$store.state.curAccountId.length > 1) {
        return '';
      }
      for (let item in this.accounts) {
        if (this.accounts[item].id === this.selectedAccountId[0]) {
          return '(' + this.accounts[item].account + ')';
        }
      }
      return '';
    },
    curAccountName: function () {
      if (this.$store.state.curAccountId.length > 1) {
        return '已选择多账号';
      }
      for (let item in this.accounts) {
        if (this.accounts[item].id === this.selectedAccountId[0]) {
          return this.accounts[item].nickname;
        }
      }
      return '';
    }
  },
  watch: {
    option: {
      handler () {
        chart.setOption(this.option)
      },
      deep: true
    },
  },
  beforeCreate () {
    if (this.$store.state.accounts.length === 0) {
      this.$message({
        message: '请先添加期货监控中心账户',
        type: 'warning'
      });
      this.$router.push({name: 'account'})
    } else if (this.$store.state.curAccountId.length === 0) {
      let curAccount = this.$store.state.accounts[0];
      this.$store.commit('SET_CUR_ACCOUNT_ID', [curAccount.id]);
    }
  },
  mounted () {
    chart = echarts.init(this.$refs.chart);
    window.onresize = chart.resize;
    let that = this
    chart.on('datazoom', function (params) {
      // that.debounce(that.refreshEchart(params),5000)
      that.refreshEchart(params)
    })
  },
  beforeDestroy () {
    chart.clear()
  },
  methods: {
    refreshEchart () {
      var startValue = chart.getOption().dataZoom[0].startValue; 
      var endValue = chart.getOption().dataZoom[0].endValue; 
      if (startValue ===0 && endValue - startValue < 50) {
        this.page = this.page+1
        this.start = startValue+49
        this.end = endValue+49
        this.getData()
      }
    },
    debounce(fn, delay) {
      let timer;
      return function() {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      }
    },
		
    changeHyText () {
      this.dialogVisible = true
    },
    remoteMethod(query) {
      if (query !== '') {
        this.selectLoading = true;
        apiContract({w: query}).then(res => {
          if (res.data.code === 0){
            this.options = res.data.data
            this.selectLoading = false
          }   
        })
      } else {
        this.options = [];
      }
    },
    hyConfirm () {
      this.dialogVisible = false
      if (!this.typeValue) {
        this.hyText = '请选择合约'
      } else {
        this.hyText = this.options.find(item => item.c === this.typeValue).n
        this.contract = this.typeValue
        data = []
        totalData = []
        this.page = 1
        this.getData()
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    // 切换日线分钟线
    changeFirstText (v) {
      this.firstText = v==='daily'?'日线':'1分钟'
      data = []
      totalData = []
      this.page = 1
      this.getData()
    },
    changeSecondText (v) {
      this.secondText = v
      let option = {...this.option}
      if (v === 'VOL(5,10)') {
        option.series[1] = {
          name: 'VOL',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: data.vols,
          itemStyle: {
            color: function (params) {
              let colorList;
              if (data.datas[params.dataIndex][1] > data.datas[params.dataIndex][0]) {
                colorList = '#ef232a';
              } else {
                colorList = '#14b143';
              }
              return colorList;
            },
          }
        }
        option.series[9] = {
          name: 'MA1',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.calculateVolMA(5, data),
          color: '#e6a23c',
          lineStyle: {
            width: 1
          }
        }
        option.series[10] = {
          name: 'MA2',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.calculateVolMA(10, data),
          color: '#CC00FF',
          lineStyle: {
            width: 1
          }
        }
      } else if (v === 'RSI(6,12,24)') {
        option.series[1] = {
          name: 'RSI1',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          color: '#e6a23c',
          lineStyle: {
            width: 1
          },
          data: this.calculateVolMA(1, data),
        }
        option.series[9] = {
          name: 'RSI2',
          type: 'line',
          showSymbol: false,
          color: '#CC00FF',
          xAxisIndex: 1,
          yAxisIndex: 1,
          lineStyle: {
            width: 1
          },
          data: this.calculateVolMA(10, data),
        }
        option.series[10] = {
          name: 'RSI3',
          type: 'line',
          color: '#DD0',
          showSymbol: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          lineStyle: {
            width: 1
          },
          data: this.calculateVolMA(20, data)
        }
      }
      this.option = option
    },
    changeThirdText (v) {
      this.thirdText = v
      let option = {...this.option}
      if (v === 'KDJ(9,3,3)') {
        option.series[2] = {
          name: 'K',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 2,
          yAxisIndex: 2,
          data: this.calculateMA(1, data),
          smooth: true,
          color: '#e6a23c',
          lineStyle: {
            width: 1
          }
        }
        option.series[3] = {
          name: 'D',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 2,
          yAxisIndex: 2,
          data: this.calculateMA(10, data),
          color: '#CC00FF',
          lineStyle: {
            width: 1
          }
        }
        option.series[4] = {
          name: 'J',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 2,
          yAxisIndex: 2,
          data: this.calculateMA(20, data),
          color: '#DD0',
          lineStyle: {
            width: 1
          }
        }
      } else if (v === 'MACD(12,26,9)') {
        option.series[2] = {
          name: 'MACD',
          type: 'bar',
          xAxisIndex: 2,
          yAxisIndex: 2,
          data: data.macd.macd,
          barWidth: 1,
          itemStyle: {
            color: (params) => {
              let colorList;
              if (params.data >= 0) {
                colorList = '#ef232a';
              } else {
                colorList = '#14b143';
              }
              return colorList;
            },
          }
        }
        option.series[3] = {
          name: 'DIF',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 2,
          yAxisIndex: 2,
          lineStyle: {
            width: 1
          },
          data: data.macd.dif
        }
        option.series[4] = {
          name: 'DEA',
          type: 'line',
          showSymbol: false,
          xAxisIndex: 2,
          yAxisIndex: 2,
          lineStyle: {
            width: 1
          },
          data: data.macd.dea
        }
      }
      this.option = option
    },
    changeAccount (v) {
      this.selectedAccountId = [v];
      // this.$store.commit('SET_CUR_ACCOUNT_ID', this.selectedAccountId);
    },
    async getData () {
      //数据模型 time0 open1 close2 min3 max4 vol5 dea6 dif7 macd8
      //['2015-10-19',18.56,18.25,18.19,18.56,55.00,0,-0.00,0.08,0.09]
      // data = this.splitData([
      // ["2019-01-02", 51.83632000, 49.95672000, 49.76368000, 52.21224000, 85554.4500]
      // ]);
      if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        // let loadingInstance = Loading.service({target: '.h-chart'});
        let period = this.firstText==='日线'?'daily':'minute'
        let res = await apiKline({contract: this.contract, period, page:this.page, size:50})
        if (res.data.code === 0 && res.data.data) {
          totalData = totalData.concat(res.data.data)
          let reverseData = JSON.parse(JSON.stringify(totalData));
          const dataTemp = reverseData.reverse().map(it => {
            let arr = []
            arr[0] = period==='minute' ? this.$moment(it.t).format('YYYY-MM-DD h:mm:ss') : this.$moment(it.t).format('YYYY-MM-DD')
            arr[1] = it.o
            arr[2] = it.e
            arr[3] = it.l
            arr[4] = it.h
            arr[5] = it.v
            return arr
          })
          data = this.splitData(dataTemp)
          this.setChartData(data)
          this.setMarkPoint()
          let st=this.$moment(totalData[totalData.length-1].t).format('YYYY-MM-DD')
          let et=this.$moment(totalData[0].t).format('YYYY-MM-DD')
          this.setMarkLine(st,et, period)
          // loadingInstance.close();
        }
    },
    splitData (rawData) {
      let datas = [];
      let times = [];
      let vols = [];
      let macd = {
        dif: [],
        dea: [],
        macd: [],
      };
      
      for (let i = 0; i < rawData.length; i++) {
        datas.push(rawData[i]);
        times.push(rawData[i].splice(0, 1)[0]);
        vols.push(rawData[i][4]);
      }
      return {
        datas: datas,
        times: times,
        vols: vols,
        macd: macd,
      };
    },
    setMarkPoint () {
      const params = [
        // {
        //   name: '开仓',
        //   coord: ['2020-03-04', 66],
        //   value: '',
        //   color: '#FF00CC',
        // },
        // {
        //   name: '开仓',
        //   coord: ['2020-03-04', 66],
        //   value: '',
        //   color: '#FFCC00',
        // },
        // {
        //   name: '平仓',
        //   coord: ['2020-07-03', 82],
        //   value: '',
        //   color: '#FF00CC',
        //   symbolOffset: [0, 0],
        // },
        // {
        //   name: '开仓',
        //   coord: ['2020-06-05', 64],
        //   value: '',
        //   color: '#FFCC00',
        //   symbolOffset: [0, 0],
        // },
        // {
        //   name: '平仓',
        //   coord: ['2020-07-03', 82],
        //   value: '',
        //   color: '#FFCC00',
        //   symbolOffset: [0, -40],
        // },
        // {
        //   name: '平仓',
        //   coord: ['2020-07-03', 82],
        //   value: '',
        //   color: '#FFCC00',
        //   symbolOffset: [0, -40],
        // }
      ]
      let cyclePoints = []
      let markerPoint = params.map((point, index) => {
        point.symbolOffset = [0, 0]
        if (index > 0) {
          cyclePoints.push(point)
          cyclePoints.forEach(cyclePoint => {
            if (point.name === cyclePoint.name && point.coord[0] === cyclePoint.coord[0]) {
              point.name === '开仓' ? point.symbolOffset[1] += 40 : point.symbolOffset[1] -= 40
            }
          })
        }
        point.itemStyle = {
          color: point.color
        }
        point.label = {
          color: point.color,
          fontWeight: 'bolder',
          fontSize: 20
        }
        return point
      })
      markerPoint.push({
        type: 'max',
        name: '最高价',
        label: {
          color: '#FD1050',
        },
        valueDim: 'highest',
        symbolOffset: [-35, -10],
        symbolSize: function (value, param) {
          let size = 15
          if (param.name === '最高价' || param.name === '最低价') {
            size = 0.1
          }
          return size
        },
      },)
      markerPoint.push({
        type: 'min',
        name: '最低价',
        valueDim: 'lowest',
        symbolOffset: [35, 10],
        symbolSize: function (value, param) {
          let size = 15
          if (param.name === '最高价' || param.name === '最低价') {
            size = 0.1
          }
          return size
        },
      })
      this.option.series[0].markPoint.data = markerPoint
    },
    async setMarkLine (b_date,e_date,period) {
      let res = await apiKlinePoint({
        a_id: this.$store.state.curAccountId.join(','),
        period,
        contract: this.contract,
        b_date,
        e_date
      })
      let params = []
      let str = period==='minute' ? 'YYYY-MM-DD h:mm:ss' : 'YYYY-MM-DD'
      if (res.data.code === 0 && res.data.data) {
        params = res.data.data.map(it =>{
          let obj = {}
          obj.label = ' ',
          obj.start = [this.$moment(it.s[0]).format(str),it.s[1]]
          obj.end = [this.$moment(it.e[0]).format(str),it.e[1]]
          return obj
        })
      }
      // const params = [
      //   {
      //     label: '头文字D',
      //     start: ['2020-03-04', 68],
      //     end: ['2020-07-03', 82]
      //   },
      //   {
      //     label: '真相只有一个',
      //     start: ['2020-06-05', 64],
      //     end: ['2020-07-03', 82]
      //   }
      // ]
      let markerLine = params.map(line => {
        let data = [
          {
            coord: line.start
          },
          {
            coord: line.end
          }
        ]
        return data
      })
      this.option.series[0].markLine.data = markerLine
    },
    setChartData (data) {
      let option = {...this.option}
      option.series[0].data = data.datas
      option.series[1].data = data.vols
      option.series[2].data = this.calculateMA(1, data), // k
      option.series[3].data = this.calculateMA(10, data), //d
      option.series[4].data = this.calculateMA(20, data), //j
      option.series[5].data = this.calculateMA(5, data)
      option.series[6].data = this.calculateMA(10, data)
      option.series[7].data = this.calculateMA(20, data)
      option.series[8].data = this.calculateMA(30, data)
      option.series[9].data = this.calculateVolMA(5, data)
      option.series[10].data = this.calculateVolMA(10, data)
      option.tooltip.formatter = (params) => {
        this.tooltipFormatter(params, data)
      }
      option.xAxis.forEach(item => {
        item.data = data.times
      })
      option.dataZoom[0].startValue =  this.start || 5
      option.dataZoom[0].endValue = this.end || 49
      option.dataZoom[1].startValue =  this.start || 5
      option.dataZoom[1].endValue = this.end || 49
      option.dataZoom[2].startValue =  this.start || 5
      option.dataZoom[2].endValue = this.end || 49
    },
    tooltipFormatter (params, data) {
      for (let i = 0; i < params.length; i++) {
        let el = params[i]
        // kline0 vol1 macd2 dif3 dea4 ma55 ma106 ma306
        switch (el.seriesIndex) {
          case 0:
            this.kTooltips = {
              color: el.color,
              renderData: [
                {
                  name: "时间",
                  value: el.name
                },
                {
                  name: "开盘",
                  value: el.value[1]
                },
                {
                  name: "收盘",
                  value: el.value[2]
                },
                {
                  name: "最低",
                  value: el.value[3]
                },
                {
                  name: "最高",
                  value: el.value[4]
                },
                {
                  name: "涨跌",
                  value: ((el.value[2] - el.value[1]) / el.value[1] * 100).toFixed(2) + '%',
                  color: el.color,
                  upOrDown: (el.value[2] - el.value[1]) <= 0 ? ' ↓' : ' ↑'
                },
                {
                  name: "振幅",
                  value: (Math.abs(el.value[4] - el.value[3]) / el.value[3] * 100).toFixed(2) + '%',
                  // upOrDown: (el.value[4] - el.value[3]) <= 0 ? ' ↓': ' ↑'
                }
              ]
            }
            break
          case 1:
            this.volTooltips = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (data.vols[el.dataIndex] - data.vols[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break;
          case 2:
            this.macdTooltips = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (data.macd.macd[el.dataIndex] - data.macd.macd[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 3:
            this.difTooltips = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (data.macd.dif[el.dataIndex] - data.macd.dif[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 4:
            this.deaTooltips = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (data.macd.dea[el.dataIndex] - data.macd.dea[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 5:
            this.kMA.ma5 = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (this.kMA.MAData5[el.dataIndex] - this.kMA.MAData5[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 6:
            this.kMA.ma10 = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (this.kMA.MAData10[el.dataIndex] - this.kMA.MAData10[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 7:
            this.kMA.ma20 = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (this.kMA.MAData20[el.dataIndex] - this.kMA.MAData20[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 8:
            this.kMA.ma30 = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (this.kMA.MAData30[el.dataIndex] - this.kMA.MAData30[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 9:
            this.volMA.ma5 = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (this.volMA.MAData5[el.dataIndex] - this.volMA.MAData5[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
          case 10:
            this.volMA.ma10 = {
              name: el.seriesName,
              value: el.value,
              color: el.color,
              upOrDown: (this.volMA.MAData10[el.dataIndex] - this.volMA.MAData10[el.dataIndex - 1]) <= 0 ? ' ↓' : ' ↑'
            }
            break
        }
      }
    },
    calculateMA (dayCount, data= {datas: []}) {
      let result = [];
      for (let i = 0, len = data.times.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        let sum = 0;
        for (let j = 0; j < dayCount; j++) {
          sum += data.datas[i - j][1];
        }
        result.push((sum / dayCount).toFixed(2));
      }
      this.kMA['MAData' + dayCount] = result
      return result;
    },
    calculateVolMA (dayCount, data) {
      let result = [];
      for (let i = 0, len = data.times.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        let sum = 0;
        for (let j = 0; j < dayCount; j++) {
          sum += data.vols[i - j];
        }
        result.push((sum / dayCount).toFixed(2));
      }
      this.volMA['MAData' + dayCount] = result
      return result;
    },
    fullPage () {
      this.enlargeShow=!this.enlargeShow
      setTimeout(()=>{chart.resize()}, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
  .kline-page {
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 195px);
    position: relative;
    // margin-bottom: 110px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    ::v-deep.el-dialog__body {
      .el-select {
        width: 100%;
      }
    }
    .header {
      background: #1777FF;
      color: #fff;
      line-height: 45px;
      height: 45px;
      position: relative;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      .header-absolute {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 45px;
        width: 250px;
        display: flex;
        justify-content: end;
        align-items: center;
        ::v-deep.el-select {
          flex: 0 0 120px;
          margin-right: 10px;
          .el-input__inner {
            display: block;
            margin-top: 0px;
          }
          .el-input__prefix, .el-input__suffix {
            top: -4px;
          }
        }
        .el-dropdown {
          color: #fff;
          cursor: pointer;
          margin-right: 5px;
        }
        .enlarge-btn {
          cursor: pointer;
          font-size: 14px;
        }
        .my-dropdown-link {
          font-size: 14px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .el-container {
      height: 100vh;

      .el-header {
        background: #19232d;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          height: 30px;
          width: 340px;
        }

        .el-dropdown-link {
          cursor: pointer;
          color: #333;
          margin: 0 0 0 5px;

          &:hover {
            /*color: #409EFF;*/
          }
        }
      }

      // .el-main {
      //   padding: 0;
      //   height: 100%;
      //   position: relative;
      // }
    }
    .chart-box {
      position: absolute;
      // position: relative;
      width: 100%;
      min-height: 1000px;
      height: 100%;
      // min-height: 100vh;

      .chart {
        width: 100%;
        height: 100%;
      }

      .k-tooltip {
        position: absolute;
        top: -8px;
        z-index: 2;
        color: #333;
        font-size: 12px;
        padding: 20px 0 0;

        > div {
          display: flex;
          margin: 5px 0;

          > p {
            margin: 0 5px;
          }
        }
      }

      .vol-tooltip {
        position: absolute;
        z-index: 2;
        top: 68%;
        color: #333;
        font-size: 12px;
        display: flex;

        > p {
          margin: 0 5px;
        }
      }

      .macd-tooltip {
        position: absolute;
        top: 82%;
        color: #333;
        font-size: 12px;
        display: flex;

        > p {
          margin: 0 5px;
        }
        .tooltip-title {
          font-size: 14px;
          padding-left: 5px;
          line-height: 14px;
          margin-right: 10px;
        }
      }

      .el-dropdown-link {
        cursor: pointer;
        color: #333;
        margin: 0 0 0 5px;

        &:hover {
          color: #409EFF;
        }
      }
    }
    .full-page {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      height: calc(100vh - 60px);
      bottom: 0px;
      z-index: 1900;
    }
  }
</style>
